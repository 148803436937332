import styled from "styled-components";

const PickemLayout = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(2, minmax(250px, 1fr));
  grid-template-rows: auto auto;
  gap: 16px;
`;

const Card = styled.li`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-template-columns: 1fr 1fr 3fr;
  font-size: 1.2rem;
  justify-items: center;
  padding: 0;

  img {
    height: 70px;
    width: auto;
  }
`;

const Picks = styled.div`
  background: #505050;
  max-width: 100%;
  height: 100%;
  max-width: fit-content;
`;

const GroupTitle = styled.div`
  display: flex;
  width: 100%;
  font-size: 1.4rem;
  font-weight: 550;
  padding: 8px;
  padding-bottom: 0;
  align-content: flex-end;
`;

const LoadPickemsBar = styled.div`
  display: flex;
  flex-direction: row;
`;

export { Card, Picks, GroupTitle, LoadPickemsBar };
export default PickemLayout;
