import C9 from "../images/logos/Cloud9.png";
import DWG from "../images/logos/DWGKIA.png";
import DTN from "../images/logos/DetonatioNGaming.png";
import IE from "../images/logos/InfinityEsports.png";
import IW from "../images/logos/IstanbulWildcats.png";
import MAD from "../images/logos/MADLions.png";
import PNG from "../images/logos/PaiNGaming.png";
import PGG from "../images/logos/PentanetGG.png";
import PSG from "../images/logos/PSGTalon.png";
import RNG from "../images/logos/RoyalNeverGiveUp.png";
import UOL from "../images/logos/UnicornsOfLove.png";

const groupsExportSourceData = [
  [
    {
      id: "pgg",
      name: "Pentanet.GG",
      thumb: PGG,
    },
    {
      id: "rng",
      name: "Royal Never Give Up",
      thumb: RNG,
    },
    {
      id: "uol",
      name: "Unicorns of Love",
      thumb: UOL,
    },
  ],
  [
    {
      id: "iw",
      name: "Istanbul Wildcats",
      thumb: IW,
    },
    {
      id: "mad",
      name: "MAD Lions",
      thumb: MAD,
    },
    {
      id: "png",
      name: "paiN Gaming",
      thumb: PNG,
    },
    {
      id: "psg",
      name: "PSG Talon",
      thumb: PSG,
    },
  ],
  [
    {
      id: "c9",
      name: "Cloud 9",
      thumb: C9,
    },
    {
      id: "dtn",
      name: "DetonatioN FocusMe",
      thumb: DTN,
    },
    {
      id: "dwk",
      name: "DWG KIA",
      thumb: DWG,
    },
    {
      id: "ie",
      name: "Infinity Esports",
      thumb: IE,
    },
  ],
];

const rumbleExportSourceData = [
  [
    {
      id: "c9",
      name: "Cloud 9",
      thumb: C9,
    },
    {
      id: "dwk",
      name: "DWG KIA",
      thumb: DWG,
    },
    {
      id: "mad",
      name: "MAD Lions",
      thumb: MAD,
    },
    {
      id: "pgg",
      name: "Pentanet.GG",
      thumb: PGG,
    },
    {
      id: "psg",
      name: "PSG Talon",
      thumb: PSG,
    },
    {
      id: "rng",
      name: "Royal Never Give Up",
      thumb: RNG,
    },
  ],
];

export { rumbleExportSourceData };
export default groupsExportSourceData;
